<template>
  <layout-popover-menu
    v-bind="$attrs"
    v-on="$listeners"
    :is-activator-button="true"
    :color="buttonColor"
  >
    <!-- Menu label -->
    <template v-slot:default>
      {{ menuLabelView }}
      <v-icon :class="spacingClass(spacingDirection.left)">{{
        iconMenuDown
      }}</v-icon>
    </template>

    <!-- Popover Editor Dialog Icon -->
    <template v-slot:popoverIconName>
      {{ iconConfigure }}
    </template>

    <!-- Popover Dialog Title -->
    <template v-slot:popoverTitle>
      {{ title }}
    </template>

    <!-- Popover Dialog Cancel -->
    <template v-slot:iconCrossName>
      <base-tooltip-button @click="closePopoverMenu()">
        <template v-slot:icon-name>
          {{ iconClose }}
        </template>
        <template v-slot:toolTip>
          {{ closePopoverTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- popover About Context-->
    <template v-slot:popoverContext>
      <viewer-properties></viewer-properties>
    </template>

    <!-- popover About Actions-->
    <template v-slot:popoverActions>
      <base-tooltip-button @click="closePopoverMenu">{{
        closePopoverLabel
      }}</base-tooltip-button>
    </template>
  </layout-popover-menu>
</template>

<script>
// design
import {
  iconClose,
  iconConfigure,
  iconMenuDown
} from "@/design/icon/iconConst";

// mixins
import { popoverMenuMixin } from "@/mixins/shared/base/popover/popoverMenuMixin";
import { spaceableMixin } from "@/mixins/shared/space/spaceableMixin";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

export default {
  name: "PopoverMenuViewerProperties",
  inheritAttrs: false,
  mixins: [popoverMenuMixin, spaceableMixin, menuItemSettingsMixin],
  components: {
    LayoutPopoverMenu: () =>
      import("@/components/shared/core/layout/menu/LayoutPopoverMenu"),
    BaseTooltipButton: () =>
      import("@/components/shared/base/BaseTooltipButton"),
    ViewerProperties: () =>
      import("@/components/shared/core/viewer/ViewerProperties")
  },
  props: {
    buttonColor: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      iconClose: iconClose,
      iconConfigure: iconConfigure,
      iconMenuDown: iconMenuDown
    };
  },
  computed: {
    menuLabelView() {
      return "View";
    },
    title() {
      return "View";
    },
    closePopoverTooltip() {
      return `${this.closePopoverLabel} ${this.menuLabelAbout}`;
    }
  }
};
</script>
